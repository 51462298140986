import {User} from "@/models/User";

export class UpdateProfileRequest {

    name: string;
    surname: string;
    email: string;

    constructor(profile: User) {
        this.name = profile.name;
        this.surname = profile.surname;
        this.email = profile.email;
    }

}
