





























































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {User} from "@/models/User";
import {profileService} from "@/services";
import {ChangePasswordRequest} from "@/models/request/ChangePasswordRequest";
import {
  compareRule,
  emailExistRule,
  emailRule,
  maxLengthRule,
  passwordRule,
  requiredRule,
  VForm
} from "@/utils/form-utils";
import {UpdateProfileRequest} from "@/models/request/UpdateProfileRequest";
import {WatchOptions} from "vue";
import {EventBus} from "@/main";
import {UserNotification} from "@/models/UserNotification";
import {UserNotificationType} from "@/models/UserNotificationType";
import {Message} from "@/models/Message";

@Component({
  components: {},
})
export default class ProfileView extends Vue {

  $refs!: {
    changePasswordForm: VForm,
    editProfileForm: VForm
  }

  profile: User = {} as User;

  changePasswordFormValid: boolean = false;
  changePasswordDialog: boolean = false;
  changePasswordServerError: string = null;
  changePasswordRequest: ChangePasswordRequest = {} as ChangePasswordRequest;
  changePasswordRules = {

    passwordRules: [
      requiredRule()
    ] as Array<Function>,

    newPasswordRules: [
      requiredRule(),
      passwordRule()
    ] as Array<Function>,

    newPasswordRepeatRules: [
      requiredRule(),
      passwordRule()
    ] as Array<Function>

  }

  editProfileFormValid: boolean = false;
  editProfileDialog: boolean = false;
  editProfileServerError: string = null;
  editProfileRequest: UpdateProfileRequest = {} as UpdateProfileRequest;
  editProfileRules = {

    nameRules: [
      requiredRule(),
      maxLengthRule(100)
    ] as Array<Function>,

    surnameRules: [
      requiredRule(),
      maxLengthRule(100)
    ] as Array<Function>,

    emailRules: [
      requiredRule(),
      emailRule(),
      maxLengthRule(100)
    ] as Array<Function>

  }

  apiErrorMessages: any = {
    editProfileRequest: {
      email: []
    }
  }

  @Watch('editProfileRequest', {deep: true} as WatchOptions)
  async editProfileRequestApiValidation(): Promise<void> {

    if (this.editProfileRequest.email !== this.profile.email) {

      let emailExistsResult: string | boolean = await emailExistRule()(this.editProfileRequest.email);

      if (emailExistsResult !== true) {
        this.apiErrorMessages.editProfileRequest.email.push(emailExistsResult);
      }

    }

  }

  async created(): Promise<void> {
    await this.loadProfile();

    this.changePasswordRules.newPasswordRules.push(compareRule(this, 'changePasswordRequest', 'newPassword', 'newPasswordRepeat', 'Pole Hasło musi być identyczne z polem Nowe powtórzone hasło'));
    this.changePasswordRules.newPasswordRepeatRules.push(compareRule(this, 'changePasswordRequest', 'newPassword', 'newPasswordRepeat', 'Pole Nowe powtórzone hasło musi być identyczne z polem Nowe hasło'));

  }

  async loadProfile(): Promise<void> {
    this.profile = await profileService.getProfile();
    this.editProfileRequest = new UpdateProfileRequest(this.profile);
  }

  cancelChangePassword(): void {
    this.changePasswordDialog = false;
    this.changePasswordRequest = {} as ChangePasswordRequest;
  }

  async revalidateChangePasswordForm(): Promise<void> {
    await this.$refs.changePasswordForm.validate();
  }

  async changePassword(): Promise<void> {

    await this.$refs.changePasswordForm.validate();

    if (this.changePasswordFormValid) {
      let response: Message = await profileService.changePassword(this.changePasswordRequest);

      if (response.message == 'password_incorrect') {
        this.changePasswordServerError = 'Aktualne hasło jest nieprawidłowe';
      } else if (response.message == 'passwords_not_equal') {
        this.changePasswordServerError = 'Pola Nowe hasło oraz Powtórzone nowe hasło nie są takie same';
      } else if (response.message == 'changed') {
        this.changePasswordServerError = null;
        this.changePasswordDialog = false;
        this.changePasswordRequest = {} as ChangePasswordRequest;
        EventBus.$emit('send-notification', new UserNotification(UserNotificationType.SUCCESS, 'Hasło zostało zmienione'));
      }

    }

  }

  async cancelEditProfile(): Promise<void> {
    this.editProfileDialog = false;
    await this.loadProfile();
  }

  async editProfile(): Promise<void> {

    await this.$refs.editProfileForm.validate();

    if (this.editProfileFormValid) {
      this.editProfileServerError = null;

      let response: Message | User = await profileService.updateProfile(this.editProfileRequest);

      if ((<Message>response).message == 'email_exists') {
        this.editProfileServerError = 'Podany adres email jest już zajęty';
      } else {
        this.editProfileDialog = false;
        EventBus.$emit('send-notification', new UserNotification(UserNotificationType.SUCCESS, 'Zmodyfikowano dane użytkownika'));
        EventBus.$emit('profileChange', true);
        await this.loadProfile();
      }

    }

  }

}
